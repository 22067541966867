import React from 'react';
import { graphql } from "gatsby";
import parse from 'html-react-parser';

import Layout from "../../components/layout"
import Seo from "../../components/seo"

//sections
import SinglePost from '../sections/singleBlogPost/SinglePost';

const AccessibilityVisualImpairment = ({data}) => {
  const mainData = data.allWpPage.nodes[0].accessibilityVisualImpairmentACF;
  return (
    <Layout>
      <Seo title="Accessibility, Visual Impairment are these terms familiar to you" />
      <div className="page-wrapper single-blog-page" id="single-blog-page-wrapper">
        <SinglePost 
            blogUrl={mainData.blogHeaderBackUrl}
            blogTitle={parse(mainData.blogHeaderTitle)}
            tags={mainData.blogHeaderTagsList}
            minutesRead={parse(mainData.bogHeaderMinReads)}
            blogDate={mainData.blogHeaderDate}
            image1x={
              {
                sourceUrl: mainData.blogHeaderImage1x.sourceUrl,
                altText: mainData.blogHeaderImage1x.altText,
                width: mainData.blogHeaderImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.blogHeaderImage2x.sourceUrl,
                altText: mainData.blogHeaderImage2x.altText,
                width: mainData.blogHeaderImage2x.width,
              }
            }
            tableOfContentsList={mainData.blogTableOfContents}
            blogContentList={mainData.blogContent}
            footerTitle={parse(mainData.blogFooterTitle)}
            footerDescription={parse(mainData.blogFooterDescription)}
            workList={mainData.blogFooterWorkList}
        />
      </div>
    </Layout>
  )
}

export const singleBlogQuery = graphql`
{
  allWpPage(filter: {slug: {eq: "accessibility-visual-impairment-are-these-terms-familiar-to-you"}}) {
    nodes {
      accessibilityVisualImpairmentACF {
        blogHeaderBackUrl
        blogHeaderTitle
        blogHeaderTagsList {
          tagName
        }
        bogHeaderMinReads
        blogHeaderDate
        blogHeaderImage1x {
          altText
          sourceUrl
          width
        }
        blogHeaderImage2x {
          altText
          sourceUrl
          width
        }
        blogTableOfContents {
          contentName
          extraClasses
          id
        }
        blogContent {
          contentName
          contentDescription
          contentImageList {
            image1x {
              altText
              sourceUrl
              width
            }
            image2x {
              altText
              sourceUrl
              width
            }
          }
          id
        }
        blogFooterTitle
        blogFooterDescription
        blogFooterWorkList {
          blogUrl
          image1x {
            altText
            sourceUrl
            width
          }
          image2x {
            altText
            sourceUrl
            width
          }
          workTitle
          workDescription
          workCategory
        }
      }
    }
  }
}`

export default AccessibilityVisualImpairment;

